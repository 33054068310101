( function() {

    const toggleFooterLinks = () => {
        var links = document.querySelectorAll('footer .js-toggle');

        if ( ! links) {
            return 
        } else {

            links.forEach(function (link) {
                link.addEventListener('click', (ev) => {
                    var targetEl = link.parentNode.nextElementSibling;
                    link.classList.toggle('active');
                    targetEl.classList.toggle('active');
                });
            });
        }
    }

    const stickyMainMenu = () => {
        var sticky = new Waypoint.Sticky({
            element: document.getElementById('main-navigation'),
            offset: -1 // small offset allows it to be unstuck at top in <768px viewport
        });
    }

    const convertImages = (query, callback) => {
        const images = document.querySelectorAll(query);
      
        images.forEach(image => {
          fetch(image.src)
          .then(res => res.text())
          .then(data => {
            const parser = new DOMParser();
            const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');
      
            if (image.id) svg.id = image.id;
            if (image.className) svg.classList = image.classList;
      
            image.parentNode.replaceChild(svg, image);
          })
          .then(callback)
          .catch(error => console.error(error))
        });
    }

    document.addEventListener("DOMContentLoaded", () => {
        /**
         * Init lazyload - used for lazyloading images and iframes that have class "lazy".
         * Plugin is enqueued in functions.php (commented out)
         * more examples: https://github.com/verlok/vanilla-lazyload#-getting-started---html
         */
        //var lazyLoadInstance = new LazyLoad();

        var rellax = new Rellax('.rellax');

        toggleFooterLinks();
        stickyMainMenu();
        convertImages('.img-to-svg');

    });

}() );
